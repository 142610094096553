import AbstractAPI from '../../common/AbstractAPI';
import { HTTPMethod } from '../../common/Constants';
import SiteConstants from '../../common/SiteConstants/SiteConstants';

class FolderAPI extends AbstractAPI {
  static fetchFolderContents(uniqueId) {
    return this.request(
      SiteConstants.buildInstance().getFoldersApiBasePath() + '/' + uniqueId,
      this.buildFetchOptions()
    );
  }

  static deleteStockItemsForFolder(binUniqueId, stockItemsToDelete) {
    const contentIdsToDelete = stockItemsToDelete.map(
      (stockItem) => stockItem.contentId
    );
    const options = this.buildFetchOptions(HTTPMethod.PUT, {
      delete: contentIdsToDelete,
    });

    return this.request(
      SiteConstants.buildInstance().getFoldersApiBasePath() + '/' + binUniqueId,
      options
    );
  }

  /**
   * Set sort order for items within a folder.
   *
   * @param uniqueId {string}
   * @param contentIdsInDesiredSortOrder {number[]}
   * @returns {Promise}
   */
  static sortStockItemsForFolder(uniqueId, contentIdsInDesiredSortOrder) {
    const options = this.buildFetchOptions(HTTPMethod.PUT, {
      sortOrder: contentIdsInDesiredSortOrder,
    });

    return this.request(
      SiteConstants.buildInstance().getFoldersApiBasePath() + '/' + uniqueId,
      options
    );
  }
}

export default FolderAPI;
