import map from 'lodash/map';
import max from 'lodash/max';
import React, { useRef } from 'react';

import { WaveformDataInterface } from '../../../Audio/AudioTypes';
import { StockItem } from '../../types/StockItemTypes';
import { getSliderValue } from '../../utils';

interface AudioPlayerWaveformProps {
  waveformData?: WaveformDataInterface;
  stockItem?: StockItem;
  currentTime?: number;
  isCurrentSource?: boolean;
  handleProgressClicked?: React.EventHandler<
    React.MouseEvent | React.KeyboardEvent
  >;
  className?: string;
}

const getViewBoxHeight = (waveformData: WaveformDataInterface): number => {
  // determine max value for each stereo channel
  const leftMax = max(map(waveformData.left, (peak) => parseFloat(peak)));
  const rightMax = max(map(waveformData.right, (peak) => parseFloat(peak)));

  // use highest peak value * 2 to determine svg viewbox height
  return Math.ceil(max([leftMax, rightMax])) * 2;
};

const generateWaveformPathData = (
  waveformData: WaveformDataInterface
): string => {
  // get values for each stereo channel
  const leftPeaks = waveformData.left;
  const rightPeaks = waveformData.right;

  // determine svg path data: this iterates over the values for each channel and creates an instruction for drawing a
  // line (upward or downward from the center of the box depending on channel). there are 1000 values for each channel
  // but we only render lines for 500
  const leftPathData = map(leftPeaks, (value, i) =>
    i % 2 === 0 ? `l 0,-${value} l 1,0 l 0,${value} l -1,0 m 2,0` : null
  );
  const rightPathData = map(rightPeaks, (value, i) =>
    i % 2 === 0 ? `l 0,${value} l 1,0 l 0,-${value} l -1,0 m 2,0` : null
  );

  // determine viewBox height
  const boxHeight = getViewBoxHeight(waveformData);

  // setup svg viewBox params and join together path data from each channel
  return `M 0,${boxHeight / 2} ${leftPathData.join(' ')} M 0,${
    boxHeight / 2
  } ${rightPathData.join(' ')} Z`;
};

const AudioPlayerWaveform = (props: AudioPlayerWaveformProps): JSX.Element => {
  const {
    waveformData,
    stockItem,
    currentTime,
    isCurrentSource,
    handleProgressClicked,
    className,
  } = props;

  const seekLine = useRef(null);

  const moveSeekLine = (event) => {
    seekLine.current.classList.remove('hidden');
    seekLine.current.classList.add('block');
    seekLine.current.style.left = `${getSliderValue(event) * 100}%`;
  };

  const removeSeekLine = () => {
    seekLine.current.classList.remove('block');
    seekLine.current.classList.add('hidden');
    seekLine.current.style.left = 0;
  };

  const waveformWrapperClassNames = `flex-column order-1 relative flex justify-center flex-grow w-1/2 ${className}`;

  if (waveformData) {
    const viewBox = `0 0 1001 ${getViewBoxHeight(waveformData)}`;
    const pathData = generateWaveformPathData(waveformData);

    // use current track time to determine how far to fill in the waveform, indicating track progress
    const offset = isCurrentSource ? currentTime / stockItem.duration : 0;

    const waveformGradientId = `progress-${stockItem.id}`;

    const pathStyle = {
      fill: `url(#${waveformGradientId})`,
    };

    const initialColor = '#B9BCC1';
    const progressColor = '#0F87FF';

    return (
      <div
        role="button"
        tabIndex={0}
        className={waveformWrapperClassNames}
        onMouseMove={moveSeekLine}
        onMouseLeave={removeSeekLine}
        onClick={handleProgressClicked}
        onKeyDown={handleProgressClicked}
      >
        <div
          className="seek-line h-full w-0.5 bg-black absolute hidden"
          ref={seekLine}
        ></div>
        <svg
          className="w-auto max-w-full h-full"
          viewBox={viewBox}
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
        >
          <defs>
            <linearGradient id={waveformGradientId}>
              <stop offset={offset} stopColor={progressColor} />
              <stop offset={offset} stopColor={initialColor} />
            </linearGradient>
          </defs>
          <path d={pathData} style={pathStyle} />
        </svg>
      </div>
    );
  } else {
    return <div className={waveformWrapperClassNames} />;
  }
};

export default AudioPlayerWaveform;
