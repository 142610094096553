import { StockItemAddToFolderEvent } from '@videoblocks/events-ts/lib/storyblocks/content/StockItemAddToFolderEvent';
import { OriginArea } from '@videoblocks/events-ts/lib/storyblocks/search/OriginAreaEnum.js';
import { RelatedEventIds } from '@videoblocks/events-ts/lib/storyblocks/search/records/RelatedEventIds';

import { StockItem } from '../common/types/StockItemTypes.js';
import events from './Events';
import ShallowStockItemFactory from './ShallowStockItemFactory.js';

export default class StockItemAddToFolderEventFactory {
  static create(
    stockItem: StockItem,
    originArea: OriginArea,
    userFolderId: number,
    storyboardRequestId: string,
    storyboardSceneRequestId: string
  ): StockItemAddToFolderEvent {
    const event = new StockItemAddToFolderEvent();

    event.meta = events.meta();
    event.user = events.user();
    event.searchIdentifiers = events.getSearchIdentifiers();

    event.stockItem = ShallowStockItemFactory.create(stockItem);

    event.originArea = originArea;
    event.userFolderId = userFolderId;

    event.relatedEventIds = new RelatedEventIds();
    event.relatedEventIds.storyboardRequestId = storyboardRequestId;
    event.relatedEventIds.storyboardSceneRequestId = storyboardSceneRequestId;

    return event;
  }
}
