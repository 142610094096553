import React, { Component, RefObject } from 'react';

import {
  LegacyPopover as Popover,
  LegacyPrimaryButton as PrimaryButton,
} from '@videoblocks/storywind';

import './SignUpForFeaturePopover.less';

type Props = {
  dismissHandler?: () => void;
  postSignUpUrl: string;
  tagLine: string;
  type?: 'preview' | 'maker' | string;
  buttonText?: string;
  onButtonClick?: (event: object) => void;
  loginRedirect?: string;
};

export default class SignUpForFeaturePopover extends Component<Props> {
  containerRef: RefObject<HTMLDivElement>;
  primaryButtonRef: RefObject<HTMLAnchorElement>;

  static defaultProps = {
    loginRedirect: '/login',
  };

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.primaryButtonRef = React.createRef();
  }

  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleClickOutside.bind(this));

    this.primaryButtonRef.current?.focus();
  };

  componentWillUnmount = () => {
    document.removeEventListener(
      'mousedown',
      this.handleClickOutside.bind(this)
    );
  };

  handleClickOutside = (event) => {
    if (!this.containerRef?.current?.contains(event.target)) {
      this.props.dismissHandler();
    }
  };

  formSignUpUrl = () => {
    return '/sign-up?redirectAfterRegister=' + this.props.postSignUpUrl;
  };

  render() {
    const { type, buttonText, tagLine, dismissHandler, onButtonClick } =
      this.props;

    return (
      <div
        className={
          type === 'preview'
            ? 'previewPopover'
            : type === 'maker'
            ? 'makerPopover'
            : 'favoritePopover'
        }
        ref={this.containerRef}
      >
        <Popover
          showTriangle={false}
          trianglePosition={null}
          onCloseClick={dismissHandler}
          id="sign-up-popover"
        >
          <div className="signUpForFeaturePopover">
            <h5 className="signUpForFeaturePopover-title">{tagLine}</h5>

            <a href={this.formSignUpUrl()} ref={this.primaryButtonRef}>
              <PrimaryButton
                className="signUpForFeaturePopover-cta border-solid border-1 border-transparent focus:border-blue-500"
                onClick={onButtonClick}
              >
                {!!buttonText ? buttonText : 'Create a free account'}
              </PrimaryButton>
            </a>

            <p className="signUpForFeaturePopover-login">
              Already a member?{' '}
              <a
                className="signUpForFeaturePopover-loginLink"
                href={this.props.loginRedirect}
              >
                Log In
              </a>
            </p>
          </div>
        </Popover>
      </div>
    );
  }
}
