import React from 'react';

import { Folder } from '../../Member/Folders/types';
import { getSiteSpecificDefaultImage } from '../../common/utils';

type Props = {
  bin: Folder;
  existsInBin: boolean;
  handleBinClick: (evt: React.MouseEvent, bin: Folder) => void;
  isShared: boolean;
};

const FolderCard = ({
  bin,
  existsInBin = false,
  handleBinClick,
  isShared = false,
}: Props) => (
  <div
    role="button"
    tabIndex={0}
    key={`popover-bin-${bin.uniqueId}`}
    className="binPopoverCard hover:bg-gray-100 flex-nowrap flex mb-3 text-gray-700 cursor-pointer"
    onClick={(e) => handleBinClick?.(e, bin)}
    onKeyDown={(e) => handleBinClick?.(e, bin)}
  >
    <img
      alt="Favorite bin thumbnail"
      className="object-cover w-12 h-12 overflow-hidden align-top bg-gray-200"
      src={bin.thumbnailUrl || getSiteSpecificDefaultImage()}
      height={12}
      width={12}
    />

    <div className="flex-grow inline-block ml-3 overflow-x-hidden text-left">
      <h4 className="binPopoverCard-name overflow-ellipsis whitespace-nowrap m-0 overflow-hidden text-base font-normal">
        {bin.name}
      </h4>
      <p className="m-0 text-sm text-gray-400">
        <span>{bin.itemCount} </span>
        <span>{bin.itemCount === 1 ? 'item' : 'items'}</span>
        {isShared && <span> (Shared)</span>}
      </p>
    </div>

    {existsInBin && (
      <img
        className="fav-icon bg-none w-6 h-6"
        src="/assets/common/images/check-mark.svg"
        alt="check mark"
      />
    )}
  </div>
);

export default FolderCard;
