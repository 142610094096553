import { StockItemDisplayEvent } from '@videoblocks/events-ts/lib/storyblocks/content/StockItemDisplayEvent';
import { DisplayedStockItem } from '@videoblocks/events-ts/lib/storyblocks/content/records/DisplayedStockItem';

import events from './Events';
import ShallowStockItemFactory from './ShallowStockItemFactory';

export default class StockItemDisplayEventFactory {
  /**
   * @param {DisplayedStockItem[]} displayedStockItems
   * @returns {StockItemDisplayEvent}
   */
  static create(displayedStockItems) {
    const event = new StockItemDisplayEvent();
    event.meta = events.meta();
    event.device = events.device();
    event.user = events.user();
    event.searchIdentifiers = events.getSearchIdentifiers();
    event.urls = events.urls();
    event.stockItems = displayedStockItems;
    return event;
  }

  /**
   * @param {object} stockItem
   * @param {number} position
   * @returns {DisplayedStockItem}
   */
  static displayedStockItem(stockItem, position) {
    const record = new DisplayedStockItem();
    record.stockItem = ShallowStockItemFactory.create(stockItem);
    record.position = position;
    return record;
  }
}
