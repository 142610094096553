import events from './Events';
import StockItemDisplayEventFactory from './StockItemDisplayEventFactory';

export class DisplayEventMediator {
  events = events;

  /**
   * @type {DisplayedStockItem[]}
   */
  displayedStockItemBuffer = [];

  /**
   * @private
   * @type {?number}
   */
  pushTimer = null;

  /** @private */
  TIMER_MS = 500;

  /**
   * @param {DisplayedStockItem} displayedStockItem
   */
  push(displayedStockItem) {
    this.displayedStockItemBuffer.push(displayedStockItem);
    this.setTimer();
  }

  /** @private */
  fire() {
    this.clearTimer();
    const displayedStockItems = this.clearBuffer();
    const displayEvent =
      StockItemDisplayEventFactory.create(displayedStockItems);
    this.events.produce(displayEvent);
  }

  /**
   * @private
   * @returns {DisplayedStockItem[]}
   */
  clearBuffer() {
    const items = [...this.displayedStockItemBuffer];
    this.displayedStockItemBuffer = [];
    return items;
  }

  /** @private */
  setTimer() {
    if (this.pushTimer) {
      this.clearTimer();
    }
    this.pushTimer = setTimeout(() => this.fire(), this.TIMER_MS);
  }

  /** @private */
  clearTimer() {
    clearTimeout(this.pushTimer);
    this.pushTimer = null;
  }
}

const instance = new DisplayEventMediator();
export default instance;
