import { ReduxState } from '../../AppLoader/types';

export const selectIsPlaying = (state: ReduxState) => state.audio.isPlaying;
export const selectIsPaused = (state: ReduxState) => state.audio.isPaused;
export const selectAudioSourceUrl = (state: ReduxState) =>
  state.audio.audioSourceUrl;
export const selectCurrentTime = (state: ReduxState) => state.audio.currentTime;
export const selectDuration = (state: ReduxState) => state.audio.duration;
export const selectCurrentVolume = (state: ReduxState) =>
  state.audio.currentVolume;
export const selectPastVolume = (state: ReduxState) => state.audio.pastVolume;
export const selectAudioPending = (state: ReduxState) => state.audio.pending;
export const selectAudioMeta = (state: ReduxState) => state.audio.meta;
export const selectAudioPendingStockItem = (state: ReduxState) =>
  state.audio.pending.stockItem;
export const selectVolume = (state: ReduxState) => state.audio.volume;
export const selectAudioFiltersChanged = (state: ReduxState) =>
  state.audio.filtersChanged;
export const selectAudioContext = (state: ReduxState) => state.audio.context;
export const selectGlobalPlayerActive = (state: ReduxState) =>
  state.audio?.globalPlayerActive;
