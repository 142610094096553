import { createAction } from 'redux-actions';

import { notify } from '../../ReactToastify/containers/ReactToastifyContainer';
import { StockItem } from '../../common/types/StockItemTypes';
import FolderAPIV2 from './FolderAPI';
import { TYPES } from './types';

export const filterKeywordChanged = createAction(TYPES.FILTER_KEYWORD_CHANGED);

export function fetchFolderContent(folderUniqueId) {
  return (dispatch) => {
    dispatch(createAction(TYPES.FETCH_FOLDER_CONTENT_STARTED)(folderUniqueId));

    return FolderAPIV2.fetchFolderContents(folderUniqueId)
      .then((response) => response.json())
      .then((json) =>
        dispatch(createAction(TYPES.FETCH_FOLDER_CONTENT_COMPLETED)(json.data))
      )
      .catch((e) =>
        dispatch(createAction(TYPES.FETCH_FOLDER_CONTENT_FAILED)(e))
      );
  };
}

export function deleteFolderContent(
  folderUniqueId: string,
  deletedStockItems: StockItem[]
) {
  return (dispatch) => {
    dispatch(createAction(TYPES.DELETE_FOLDER_CONTENT_STARTED));

    return FolderAPIV2.deleteStockItemsForFolder(
      folderUniqueId,
      deletedStockItems
    )
      .then((response) => response.json())
      .then((json) => {
        dispatch(
          createAction(TYPES.DELETE_FOLDER_CONTENT_COMPLETED)({
            deletedContentIds: deletedStockItems.map(
              (stockItem) => stockItem.contentId
            ),
            binUniqueId: folderUniqueId,
            bins: json.bins,
          })
        );
        notify(`Removed from "${json.bins[folderUniqueId].name}"`);
      })
      .catch((e) => {
        dispatch(createAction(TYPES.DELETE_FOLDER_CONTENT_FAILED)(e));
        notify('Error removing item.');
      });
  };
}

export function saveReorderFolderContent(
  folderUniqueId: string,
  stockItemsInDesiredSortOrder: StockItem[]
) {
  return (dispatch) => {
    dispatch(createAction(TYPES.SAVE_REORDER_FOLDER_CONTENT_STARTED));

    const stockItemIdentifiers = stockItemsInDesiredSortOrder.map(
      (si) => si.contentId
    );

    return FolderAPIV2.sortStockItemsForFolder(
      folderUniqueId,
      stockItemIdentifiers
    )
      .then((response) => response.json())
      .then(() =>
        dispatch(
          createAction(TYPES.SAVE_REORDER_FOLDER_CONTENT_COMPLETED)({
            sortedItemIds: stockItemIdentifiers,
            binUniqueId: folderUniqueId,
          })
        )
      )
      .catch((e) =>
        dispatch(createAction(TYPES.SAVE_REORDER_FOLDER_CONTENT_FAILED)(e))
      );
  };
}

/**
 * Order stock items by moving one relative to another.
 *
 * @param moveContentId {number}
 * @param destinationContentId {number}
 * @param shouldInsertBefore {boolean}
 * @returns {{moveContentId, shouldInsertBefore, type: string, destinationContentId}}
 */
export function reorderFolderContent(
  moveContentId,
  destinationContentId,
  shouldInsertBefore
) {
  return {
    type: TYPES.REORDER_FOLDER_CONTENT,
    moveContentId: moveContentId,
    destinationContentId: destinationContentId,
    shouldInsertBefore: shouldInsertBefore,
  };
}

export const toggleSelectAll = createAction(TYPES.TOGGLE_SELECT_ALL);
