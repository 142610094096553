import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Telemetry } from '@videoblocks/kafka-rest-client';
import DownloadBottom from '@videoblocks/react-icons/DownloadBottom';

import {
  selectHasAudioSubscription,
  selectIsLoggedIn,
} from '../../../../auth/AuthSelectors';
import { promoOfferContexts } from '../../../../common/Constants';
import Popper, {
  Placements,
} from '../../../../common/components/Popper/Popper';
import {
  StockItem,
  StockItemFormat,
} from '../../../../common/types/StockItemTypes';
import { getPreviewUrl } from '../../../../common/utils';

interface Props {
  onDownloadOptionClick: (e) => void;
  stockItem: StockItem;
  stockItemFormats: StockItemFormat[];
  iconClassName: string;
  onDownloadButtonClick: () => void;
  className?: string;
}

const AudioDownloadButton = ({
  onDownloadOptionClick,
  stockItem,
  stockItemFormats,
  className,
  iconClassName,
  onDownloadButtonClick,
}: Props) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const audioSubscription = useSelector(selectHasAudioSubscription);

  const [showTooltip, setShowTooltip] = useState(false);
  const [showDownloadOptions, setShowDownloadOptions] = useState(false);

  const downloadWatermarkedPreview = () => {
    Telemetry.increment('preview.downloaded.stockItemCard');
    window.location.href = getPreviewUrl(stockItem.id, stockItem.contentClass);
  };

  const hideContent = () => {
    setShowTooltip(false);
    setShowDownloadOptions(false);
  };

  const handleMouseEnter = () => {
    if (!showDownloadOptions) {
      setShowTooltip(true);
    }
  };

  const handleDownloadButtonClick = () => {
    if (onDownloadButtonClick) {
      onDownloadButtonClick();
    }

    if (!audioSubscription) {
      downloadWatermarkedPreview();
    } else {
      setShowDownloadOptions(true);
      setShowTooltip(false);
    }
  };

  const handleDownloadOptionClick = (e) => {
    onDownloadOptionClick(e);
    hideContent();
  };

  const renderDownloadOption = (format) => {
    const formatDetails = stockItemFormats.find(
      (fmt) => fmt.formatName === format
    );

    return (
      <a
        className={`download-item-${format.toLowerCase()} hover:bg-gray-100 block p-2 text-sm text-black cursor-pointer`}
        onClick={handleDownloadOptionClick}
        data-download-url={formatDetails.downloadAjaxUrl}
        data-format={format}
        data-promo-context={promoOfferContexts.cardDownload}
        href={stockItem.detailsUrl}
        key={`format-${format}`}
      >
        {format}
      </a>
    );
  };

  return (
    <div className={className}>
      {isLoggedIn ? (
        <Popper
          hideContent={hideContent}
          hideOnOutsideClick={true}
          offset={showDownloadOptions ? [0, 8] : [0, 12]}
          placement={
            showDownloadOptions ? Placements.BottomEnd : Placements.Left
          }
          popperClasses={
            showDownloadOptions
              ? 'bg-white rounded shadow-popover'
              : 'bg-black opacity-90 rounded'
          }
          showArrow={!showDownloadOptions}
          showContent={showTooltip || showDownloadOptions}
          triggerElement={(referenceElement) => (
            <button
              className="download-button relative flex items-center content-center justify-center cursor-pointer border-none"
              onClick={handleDownloadButtonClick}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={() => setShowTooltip(false)}
              ref={referenceElement}
            >
              <DownloadBottom
                className={`action-icon fill-current ${iconClassName}`}
              />
            </button>
          )}
        >
          {showDownloadOptions ? (
            <div className="w-32">
              {stockItemFormats.map((format) =>
                renderDownloadOption(format.formatName)
              )}
            </div>
          ) : (
            <div className="px-2 py-1 text-sm text-white" id="tooltip-favorite">
              {isLoggedIn && audioSubscription
                ? 'Download'
                : 'Download Preview'}
            </div>
          )}
        </Popper>
      ) : (
        <a
          className="download-button relative flex items-center justify-center text-white rounded cursor-pointer border-none"
          href={stockItem.detailsUrl}
          aria-label={`Download audio track ${stockItem.title}`}
        >
          <DownloadBottom
            className={`action-icon fill-current ${iconClassName}`}
          />
        </a>
      )}
    </div>
  );
};

export default AudioDownloadButton;
