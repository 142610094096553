import React from 'react';

import { convertSecondsToMMSS } from '../../utils';

interface AudioPlayerDurationProps {
  duration?: number;
  className?: string;
}

const AudioPlayerDuration = ({
  duration,
  className,
}: AudioPlayerDurationProps): JSX.Element => {
  const classNames = `stock-item-duration flex items-center ${className}`;

  return (
    <div className={classNames}>
      <span className="text-sm">{convertSecondsToMMSS(duration)}</span>
    </div>
  );
};

export default AudioPlayerDuration;
