import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PauseIcon from '@videoblocks/react-icons/Pause';
import PlayIcon from '@videoblocks/react-icons/Play';

import { WaveformDataInterface } from '../../../Audio/AudioTypes';
import {
  setPendingPauseAudio,
  setPendingPlayAudio,
} from '../../../Audio/actions/AudioActions';
import {
  selectIsPlaying,
  selectCurrentTime,
  selectAudioSourceUrl,
} from '../../../Audio/selectors/audioSelectors';
import { DrawerType } from '../../../app/Search/SearchTypes';
import { StockItemArtistInterface } from '../../../app/Search/containers/MenuContainerInterfaces';
import { StockItem, StockItemFormat } from '../../types/StockItemTypes';

interface Props {
  playHandler?: () => void;
  stockItem: StockItem;
  className?: string;
  wrapperClassName?: string;
  iconClassName?: string;
  stockItemArtists?: StockItemArtistInterface[];
  stockItemFormats?: StockItemFormat[];
  waveformData?: WaveformDataInterface;
  drawerType?: string;
  context?: string;
}
const AudioPlayerPlayButton = ({
  playHandler,
  stockItem,
  stockItemArtists,
  stockItemFormats,
  waveformData,
  className,
  wrapperClassName,
  iconClassName,
  context,
  drawerType = '',
}: Props) => {
  const dispatch = useDispatch();

  const currentSource = useSelector(selectAudioSourceUrl);
  const currentTime = useSelector(selectCurrentTime);
  const isPlaying = useSelector(selectIsPlaying);

  const [isCurrentSource, setIsCurrentSource] = useState<boolean>();
  const [showPauseButton, setShowPauseButton] = useState<boolean>();
  const [playButtonClassNames, setPlayButtonClassNames] = useState(
    `audioPlayButton flex ${
      drawerType === DrawerType.RECOMMENDED_MUSIC ? 'ml-2' : ''
    }`
  );

  useEffect(() => {
    return setIsCurrentSource(stockItem.previewUrl === currentSource);
  }, [currentSource, stockItem]);

  useEffect(() => {
    setShowPauseButton(isCurrentSource && isPlaying);
  }, [isCurrentSource, isPlaying]);

  useEffect(() => {
    const activeClass = !stockItem.isActive ? 'disabled' : 'active';
    const playingClass = showPauseButton ? 'playing' : '';

    setPlayButtonClassNames(
      `audioPlayButton flex ${activeClass} ${playingClass} ${
        drawerType === DrawerType.RECOMMENDED_MUSIC ? 'ml-2' : ''
      }`
    );
  }, [stockItem, showPauseButton]);

  const pause = () => {
    dispatch(setPendingPauseAudio());
  };

  const play = () => {
    playHandler?.();

    dispatch(
      setPendingPlayAudio(
        stockItem.previewUrl,
        isCurrentSource ? currentTime : 0,
        stockItem,
        stockItemArtists,
        stockItemFormats,
        waveformData,
        context
      )
    );
  };

  const ControlsIcon = showPauseButton ? PauseIcon : PlayIcon;

  return (
    <div className={playButtonClassNames}>
      <button
        type="button"
        className={`audioPlayButton-button flex items-center cursor-pointer justify-center border-none bg-none ${className}`}
        onClick={showPauseButton ? pause : play}
        aria-label={showPauseButton ? 'pause' : 'play'}
      >
        <div
          className={`audioPlayButton-imageWrapper rounded-full flex justify-center items-center ${wrapperClassName}`}
        >
          <ControlsIcon
            className={`${iconClassName} flex justify-center items-center w-7 h-7`}
            style={{ fill: 'white' }}
          />
        </div>
      </button>
    </div>
  );
};

export default AudioPlayerPlayButton;
