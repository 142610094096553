import React, { PureComponent } from 'react';
import { twMerge } from 'tailwind-merge';

import './LoadingIndicator.less';

interface Props {
  title?: string;
  compact?: boolean;
  show?: boolean;
}

export default class LoadingIndicator extends PureComponent<Props> {
  render() {
    return this.props.title
      ? this.renderWithTitle(this.props.title)
      : this.renderPlain();
  }

  renderPlain() {
    const { compact = false, show = true } = this.props;

    const cssClasses = twMerge(
      'la-loading-indicator la-ball-newton-cradle la-2x la-delayed',
      compact && 'la-compact'
    );

    return show ? (
      <div className={cssClasses}>
        <div></div>
        <div></div>
        <div></div>
      </div>
    ) : null;
  }

  renderWithTitle(title) {
    return (
      <div className="la-loading-indicator">
        <div className="la-ball-newton-cradle la-2x la-delayed">
          <div></div>
          <div></div>
          <div></div>
        </div>
        <h3>{title}</h3>
      </div>
    );
  }
}
