import React, { ReactElement } from 'react';

import SiteConstants from '../../SiteConstants/SiteConstants';
import SignUpForFeaturePopover from './SignUpForFeaturePopover';

export interface SignUpForFoldersPopoverPropTypes {
  contentId: number;
}

const SignUpForFoldersPopover = ({
  contentId,
}: SignUpForFoldersPopoverPropTypes): ReactElement => {
  return (
    <SignUpForFeaturePopover
      postSignUpUrl={
        SiteConstants.buildInstance().getFoldersApiBasePath() +
        '/favorite-then-visit/' +
        contentId
      }
      dismissHandler={() => null}
      tagLine="Start organizing assets for your creative projects!"
      type="favorite"
    />
  );
};

export default SignUpForFoldersPopover;
