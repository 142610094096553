import * as queryString from 'query-string';
import cookie from 'react-cookies';

import { Telemetry } from '@videoblocks/kafka-rest-client';

import Logger from '../common/Logger';
import AudioSiteConstants from '../common/SiteConstants/AudioSiteConstants';
import { convertStockItemContextToOriginArea } from '../common/utils';

function getDaysSinceTimestamp(timestamp) {
  return Math.floor(
    Math.abs(Date.parse(timestamp) - new Date()) / 1000 / 86400
  );
}

export function showSearchSurvey() {
  if (typeof hj === 'function') {
    const lastSeenSearchSurvey = cookie.load('audio_survey', false) || null;

    if (
      !lastSeenSearchSurvey ||
      getDaysSinceTimestamp(lastSeenSearchSurvey) >= 14
    ) {
      hj('trigger', 'search_difficulty');
      cookie.save('audio_survey', new Date(), {
        path: '/',
        domain: '.' + window.location.hostname,
      });
    }
  }
}

export async function getDownloadPrimaryClickAction(
  event,
  stockItem,
  stockItemFormats,
  audioSubscription,
  primarySubscription,
  searchLogParameters,
  context,
  drawerType,
  dispatch,
  downloadUrlParams
) {
  Logger.produceDownloadClickEvent(stockItem, context && context.position);

  if (audioSubscription) {
    event.preventDefault();

    const format = event.target.dataset.format;
    const selectedFormat = stockItemFormats.find(
      (fmt) => fmt.formatName === format
    );

    Telemetry.increment('download.clicked.stockItemCard');

    if (drawerType) {
      Telemetry.increment(`download.clicked.${drawerType}`);
    }

    const originArea = convertStockItemContextToOriginArea(context);
    if (originArea) {
      downloadUrlParams['origin_area'] = originArea;
    }

    const dataBridge = await new AudioSiteConstants().getDataBridge();
    dataBridge.downloadItem(
      queryString.stringifyUrl(
        {
          url: selectedFormat.downloadUrl,
          query: downloadUrlParams,
        },
        {
          skipNull: true,
        }
      ),
      queryString.stringifyUrl(
        {
          url: selectedFormat.downloadAjaxUrl,
          query: downloadUrlParams,
        },
        {
          skipNull: true,
        }
      ),
      searchLogParameters,
      dispatch,
      // NOTE: used to provide data for Intercom in Starter Plan A/B Test, remove after test if possible
      primarySubscription || audioSubscription
    );

    showSearchSurvey();

    return false;
  }
}

export function saveVolumeStateInSession(key, volume) {
  if (window.sessionStorage) {
    window.sessionStorage.setItem(key, volume);
  }
}

export function fetchVolumeStateFromSession(key) {
  if (window.sessionStorage) {
    const volume = JSON.parse(window.sessionStorage.getItem(key));
    return volume !== null ? volume : 1;
  }
}

export function getPlayedClips() {
  const playedClips = JSON.parse(window.sessionStorage.getItem('playedClips'));
  return playedClips || {};
}

function savePlayInSession(stockItemId, isLoggedIn) {
  if (window.sessionStorage) {
    const playedClips = getPlayedClips();
    const lastListened = playedClips.lastListened
      ? new Date(playedClips.lastListened)
      : new Date();
    let stockItemIds = playedClips.stockItemIds || [];

    // if tab open for 7+ days, clear listen history
    const daysSinceLastListened = getDaysSinceTimestamp(lastListened);
    if (daysSinceLastListened >= 7) {
      stockItemIds = [];
    }

    // if recently logged out, clear listen history
    if (!isLoggedIn && playedClips.isLoggedIn) {
      stockItemIds = [];
    }

    stockItemIds.push(stockItemId);

    playedClips.stockItemIds = Array.from(new Set(stockItemIds));
    playedClips.lastListened = new Date();
    playedClips.isLoggedIn = isLoggedIn;

    window.sessionStorage.setItem('playedClips', JSON.stringify(playedClips));
  }
}

export function shouldShowVisitorLibraryQualitySurvey() {
  const hasSeen = JSON.parse(
    window.sessionStorage.getItem('visitor_library_quality')
  );
  return !hasSeen;
}

function showVisitorLibraryQualitySurvey(isVisitor) {
  const surveyTrigger = 'visitor_library_quality';

  if (
    typeof hj === 'function' &&
    isVisitor &&
    shouldShowVisitorLibraryQualitySurvey()
  ) {
    const playedClips = getPlayedClips();
    const numberOfClipsPreviewed = playedClips.stockItemIds
      ? playedClips.stockItemIds.length
      : 0;

    if (numberOfClipsPreviewed >= 10) {
      hj('trigger', surveyTrigger);
      window.sessionStorage.setItem(surveyTrigger, JSON.stringify(true));
    }
  }
}

export function logAudioPlay(stockItem, isLoggedIn) {
  savePlayInSession(stockItem.id, isLoggedIn);
  showVisitorLibraryQualitySurvey(!isLoggedIn);
}
