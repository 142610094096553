import { createSelector } from '@reduxjs/toolkit';

import { ReduxState } from '../../../AppLoader/types';

const selectMemberBinsInput = (state: ReduxState) => {
  return state.memberBins;
};

export const selectMemberBins = createSelector(
  [selectMemberBinsInput],
  (memberBins) => {
    const { bins = {} } = memberBins;

    return Object.values(bins) || [];
  }
);

export const selectMemberBinBinName = (state: ReduxState) =>
  state.memberBin.binName;

export const selectBinUniqueId = (state: ReduxState) =>
  state.memberBin.binUniqueId;

export const selectMemberBinName = (state: ReduxState) => state.memberBin.name;

export const selectFolderFeedback = (state: ReduxState) => state.folderFeedback;

export const selectIsInSharedFoldersBeta = (state: ReduxState) =>
  state.memberBins.isInSharedFoldersBeta;

export const selectMemberBin = (state: ReduxState) => state.memberBin;

export const selectIsLoadingFolders = (state: ReduxState) =>
  state.memberBins.isLoading;

export const selectMemberBinFeatures = (state: ReduxState) =>
  state.memberBin.features;
