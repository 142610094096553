import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { selectMemberBins } from '../../../Member/MemberBins/selectors/foldersSelectors';
import { StockItem, StockItemFormat } from '../../types/StockItemTypes';
import { isStockItemInAnyFolder } from '../../utils';
import FavoriteButton from '../FavoriteButton';
import Popper, { Placements } from '../Popper/Popper';

interface AudioPlayerFavoriteButtonProps {
  stockItem?: StockItem;
  stockItemFormats?: StockItemFormat[];
  onClick?: () => void;
  alternateIconDefaultColor?: string;
  alternateIconInFolderColor?: string;
  iconClassName?: string;
  telemetryKey?: string;
}

const AudioPlayerFavoriteButton = ({
  stockItem,
  stockItemFormats,
  onClick,
  alternateIconDefaultColor = 'text-black',
  alternateIconInFolderColor = 'text-black',
  iconClassName,
  telemetryKey,
}: AudioPlayerFavoriteButtonProps): JSX.Element => {
  const bins = useSelector(selectMemberBins);
  const [showTooltip, setShowTooltip] = useState(false);

  const tooltipText = isStockItemInAnyFolder(bins, stockItem)
    ? 'Added to Folder'
    : 'Add to Folder';

  return (
    <div>
      <Popper
        placement={Placements.Left}
        popperClasses="px-2 py-1 text-white bg-black rounded opacity-90 text-sm"
        showContent={showTooltip}
        triggerElement={(referenceElement) => (
          <div
            className="flex items-center"
            ref={referenceElement}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            <FavoriteButton
              onButtonClick={onClick}
              stockItem={stockItem}
              stockItemFormats={stockItemFormats}
              telemetryKey={telemetryKey || 'search'}
              iconClassName={iconClassName}
              alternateIconDefaultColor={alternateIconDefaultColor}
              alternateIconInFolderColor={alternateIconInFolderColor}
            />
          </div>
        )}
      >
        <div id="tooltip-favorite" className="whitespace-nowrap">
          {`${tooltipText}`}
        </div>
      </Popper>
    </div>
  );
};

export default AudioPlayerFavoriteButton;
